import React from "react";
import { AuthContext } from '../services/authService/AuthContexts';
import PropTypes from 'prop-types';

import Navigation from './Navigation';

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: false,
        };
    }

    componentDidMount() {
    }
    render() {
        const { children } = this.props;
        const { user } = this.context;
        return (
            <>
        <header>
          <Navigation User={user.profile}/>
        </header>
        <main className="container-fluid p-b-20">
          {children}
        </main>
        </>
        );
    }
}
MainLayout.contextType = AuthContext;

MainLayout.propTypes = {
  children: PropTypes.object
};

export default MainLayout;
