import axios from "axios";
import { API_GATEWAY_ENDPOINT } from "../../configs/oidc_constants";

export async function get_paths(token) {
  let email = token["profile"]["email"];
  let url = API_GATEWAY_ENDPOINT + "/clientFileUpload?email=" + email;
  let id_token = token["id_token"];

  const config = {
    headers: {
      "Authorization": id_token
    }
  };

  let paths = [];
  let paths_response = (await axios.get(url, config));

  if(paths_response.status===200)
    paths = paths_response.data;

  return paths;
}


export async function get_users(token) {
  let url = API_GATEWAY_ENDPOINT + "/users";
  let id_token = token["id_token"];
  const config = {
    headers: {
      "Authorization": id_token
    }
  };

  let users = [];
  let users_response = (await axios.get(url, config));

  if(users_response.status===200){
    users = users_response.data;
  }
  return users;
}

export async function save_user(token, user_email, permissions, create_flag) {
  let url = API_GATEWAY_ENDPOINT + "/users";
  let id_token = token["id_token"];
  const config = {
    headers: {
      "Authorization": id_token
    }
  };

  let body = {
    "User": user_email,
    "Permissions": permissions
  }

  let users_response = null;

  if(create_flag) {
    users_response = (await axios.post(url, body, config));
  }
  else {
    users_response = (await axios.put(url, body, config));
  }

  if(users_response.status===200){
    return true;
  }
  else
    return false;
}


export async function delete_user(token, user_email) {
  let url = API_GATEWAY_ENDPOINT + "/users?email="+ user_email;
  let id_token = token["id_token"];
  const config = {
    headers: {
      "Authorization": id_token
    }
  };
  let users_response = (await axios.delete(url, config));
  if(users_response.status===200) {
    return true;
  }
  else
    return false;
}

export async function get_s3_signed_url(token, path_setting) {
  let url = API_GATEWAY_ENDPOINT + "/clientFileUpload";
  path_setting["User"] = token["profile"]["email"];
  let body = JSON.stringify(path_setting);

  let signed_url =  (await axios.post(url, body, { headers: { "Authorization": token["id_token"] } })).data;
  return signed_url;
}

export async function upload_file_to_aws(signed_url, file_data, username) {
    console.log("upload_file_to_aws username: %s", username);
    try {
      let res = await axios.put(signed_url, file_data, { headers: { 'x-amz-meta-username': username} })
      return res.status;
    }
    catch (err) {
        console.error(err);
        return 401;
    }
}