import { WebStorageStateStore } from "oidc-client";
import { CLIENT_ID, USER_POOL_ID, AWS_REGION } from "./oidc_constants";

let OidcConfig = {};
if (typeof window !== "undefined") {
  const portalUrl = window.location.origin;
  // let redirect_uri = REDIRECT_URI;
  let client_id = CLIENT_ID;
  let openid_endpoint = "https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID;

  OidcConfig = {
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),    
    authority: openid_endpoint,
    client_id: client_id,
    redirect_uri: portalUrl+"/loginCallback",
    post_logout_redirect_uri: portalUrl,
    response_type: 'code',
    scope: 'openid profile email'
  };
}

export { OidcConfig };
