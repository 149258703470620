import React, { Component } from "react";
import StepWizard from "react-step-wizard";
import { Stepper, Step } from "react-form-stepper";
import {Container, Row, Col} from 'react-bootstrap';
import { get_paths } from "../../services/apiCallerService/ApiCaller";
import PathSelector from "./Step1_PathSelector";
import FileUploader from "./Step2_FileUploader";
import Confirmation from "./Step3_Confirmation";
import UploadError from "./Step4_UploadError";
import { CLIENT_ID, USER_POOL_ID, AWS_REGION } from "../../configs/oidc_constants";


class FileWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paths: [],
      selected_path: null,
      loading: true,
      activeStep: 0,
      show_error: false,
      error: 0
    };

    this.set_state_value = this.set_state_value.bind(this);
    this.next_step = this.next_step.bind(this);
    this.reset = this.reset.bind(this);
    this.set_path = this.set_path.bind(this);

    this.wizzard = React.createRef();
    this.path_selector = React.createRef();
    this.file_uploader = React.createRef();
    this.confirmation = React.createRef();
    this.error = React.createRef();

    this.handleStepChange = this.handleStepChange.bind(this);
  }

  handleStepChange(e) {
    this.setState({"activeStep": e.activeStep - 1});
  };

  set_state_value(key, value) {
    this.setState({ [key]: value });
  }

  componentDidMount() {
    if (this.state.paths.length === 0) {
      let token_id = "oidc.user:https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID + ":" + CLIENT_ID;
      let token = JSON.parse(sessionStorage.getItem(token_id));
      get_paths(token)
        .then(res=>{
          let show_error = (res.length === 0);
          this.setState({paths: res["Permissions"], loading: false, show_error: show_error});
        })
        .catch(function (error) {
          console.log("Something went wrong with signed url");
          console.log(error);
        });

    }

  }

  next_step() {
  }

  set_path(path){
    this.setState({selected_path: path});
  }

  reset() {
    window.location.reload(false);
  }

  render() {
    return (
        (this.state.show_error?
          <span>Your account is not setup to use this tool! Please contact system administrator.</span> :
          <Container>
          <Row>
            <Col>
              <Stepper activeStep={this.state.activeStep}>
                <Step label="Select Destination" />
                <Step label="Upload File" />
                <Step label="Confirmation" />
              </Stepper>
            </Col>
          </Row>
          <Row>
            <Col>
              <StepWizard ref={this.wizzard} onStepChange={this.handleStepChange}>
                <PathSelector
                  stepName={"PathSelector"}
                  paths={this.state.paths}
                  on_path_change={(path) => this.set_path(path)}
                  selected_path={this.state.selected_path}
                  next_step={() => this.wizzard.current.goToNamedStep("FileUploader")}
                  loading={this.state.loading}
                  ref={this.path_selector} />
                <FileUploader
                  stepName={"FileUploader"}
                  selected_path={this.state.selected_path}
                  next_step={() => this.wizzard.current.goToNamedStep("Confirmation")}
                  show_error={(error) => this.setState({error: error}, ()=>{this.wizzard.current.goToNamedStep("Error")})}
                  ref={this.file_uploader}
                  reset={this.reset} />

                <Confirmation
                  stepName={"Confirmation"}
                  reset={this.reset}
                  ref={this.confirmation} />

                <UploadError
                  stepName={"Error"}
                  reset={this.reset}
                  ref={this.error}
                  error={this.state.error} />

              </StepWizard>
            </Col>
          </Row>
        </Container>)

    );
  }
}

export default FileWizard;
