import { OidcConfig } from "../../configs/oidc";
import OidcClient from "oidc-client";
let client = null;
class UserManager {
  get Oidc() {
    if (!client) {
      client = new OidcClient.UserManager(OidcConfig);
    }
    return client;
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserManager();
