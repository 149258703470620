import { CLIENT_ID, USER_POOL_ID, AWS_REGION } from "../../configs/oidc_constants";


export function get_token() {
  let token_id = "oidc.user:https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID + ":" + CLIENT_ID;
  let token = JSON.parse(sessionStorage.getItem(token_id));
  return token;
}

export function get_user() {
  let token = get_token();
  if(token !== null)
    return token["profile"]["email"];
  return  null;
}

export function is_user_admin() {
  let user = get_user();
  if(user !== null)
    return user.includes("@thetradedesk.com");
  else
    return false;
}