import React, { Component } from "react";
import { ButtonGroup, Button } from 'react-bootstrap';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
          <div>
            <h1>File uploaded successfully!</h1>
            <ButtonGroup className="btn-group-wizzard">
              <Button className="btn-space" variant="outline-secondary" onClick={this.props.reset}>Back</Button>
            </ButtonGroup>
          </div>
    );
  }
}

export default Confirmation;
