import React, { Component } from "react";
import {Button, ButtonGroup, Spinner} from 'react-bootstrap';

import UsersList from "./UsersList";
import AddUser from "./AddUser";

import { get_users } from "../../services/apiCallerService/ApiCaller";
import { CLIENT_ID, USER_POOL_ID, AWS_REGION } from "../../configs/oidc_constants";
import EditUser from "./EditUser";


class UsersWizzard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      step: "user_list",
      loading_users: false,
      user_to_edit: null
    }; 

    this.switch_view = this.switch_view.bind(this);
    this.reload_users = this.reload_users.bind(this);
    this.edit_user = this.edit_user.bind(this);
  }



reload_users(){
  let self = this;
  let token_id = "oidc.user:https://cognito-idp." + AWS_REGION + ".amazonaws.com/" + USER_POOL_ID + ":" + CLIENT_ID;
  let token = JSON.parse(sessionStorage.getItem(token_id));

  this.setState({loading_users: true}, ()=>{
    get_users(token)
      .then(res=>{
        let results = res;
        self.setState({users: results, loading_users: false});
      })
      .catch(function (error) {
        console.log("Something went wrong with signed url");
        console.log(error);
      });
  });

}
componentDidMount() {
  if (this.state.users.length === 0) {
    this.reload_users();
  }  
}

switch_view(caller_view) {
  let self = this;
  this.setState({step: "user_list"},()=>{
    self.reload_users();
  });
  
}

  edit_user(user_path) {
    this.setState({step: "edit_user", user_to_edit: user_path});    
  }

  render() {
    let buckets = this.state.users["buckets"];
    let user_paths = this.state.users["user_paths"];

    return (
      <div className="home">
        {this.state.loading_users ?
          <div>
            <Spinner
              as="span"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
              variant="secondary"/>
            <span>loading...</span>
          </div>:
          <div>
            <ButtonGroup className="btn-group-wizzard">
              {this.state.step === "user_list" && <Button className="btn-space" variant="outline-primary" onClick={()=>this.setState({step: "create_user"})}>Create User</Button>}
              {this.state.step === "create_user" && <Button className="btn-space" variant="outline-primary" onClick={()=>this.setState({step: "user_list"})}>x</Button>}
            </ButtonGroup>
            {this.state.step === "create_user" && <AddUser buckets={buckets} user_paths={user_paths} switch_view={this.switch_view} />}
            
            {this.state.step === "edit_user" && 
              this.state.user_to_edit !==null && 
              <EditUser 
                buckets={buckets}
                user_paths={user_paths}
                switch_view={this.switch_view}
                user_to_edit={this.state.user_to_edit} />}

            {this.state.step === "user_list" && 
              <UsersList 
                users_list={this.state.users}
                reload_users={this.reload_users}
                edit_user={this.edit_user} />}
          </div>
        }
      </div>
    );
  }
}
export default UsersWizzard;