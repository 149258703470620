import React, { Component} from "react";
import {Form, Button, Spinner, ButtonGroup} from 'react-bootstrap';

class PathSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_path: null
    };

    this.set_selected_path = this.set_selected_path.bind(this);
    this.reset = this.reset.bind(this);

    this.form = React.createRef();
  }

  reset(){
    this.form.current.reset();
    // this.setState({selected_path: null, selectedFile: null});
  }

  onFileChange(event) {
    this.setState({ selectedFile: event.target.files[0] });
  };

  set_selected_path(path){
    let self = this;
    this.setState({selected_path: path}, ()=>{
      self.props.on_path_change(path);
    })
  }

  componentDidMount(){
    this.setState({selected_path: null});
  }


  render() {
    let self = this;
    let path_list = this.props.paths;
    return (
      <div>
      {this.props.loading &&
        <div>
          <Spinner
              as="span"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
              variant="secondary"/>
          <span>loading...</span>
        </div>
        }
        {!(this.props.loading) &&
          <Form ref={this.form}>
            <Form.Group>
              {path_list != null && path_list.length>0 &&path_list.map(function (path, index) {
                return (
                  <div key={index}>
                    <Form.Check
                      name="group1"
                      type="radio"
                      id={`path-${index}-1`}
                      label={path["DisplayName"]===""? path["Bucket"] + "/" + path["PathPrefix"] : path["DisplayName"] + " (" + path["Bucket"] + "/" + path["PathPrefix"]+ ")"}
                      onChange={(e)=>self.set_selected_path(path)} />
                  </div>)
                })
              } 
            </Form.Group>

            <ButtonGroup className="btn-group-wizzard">
              <Button className="btn-space" variant="outline-secondary" onClick={this.props.reset}>Back</Button>
              <Button className="btn-space" variant="outline-primary" disabled={this.state.selected_path===null} onClick={(e) => self.props.next_step()}>Next</Button>              
            </ButtonGroup>
          </Form>
        }
        </div>
        );
  }
}

export default PathSelector;
