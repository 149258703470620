import { Route, Switch } from "react-router-dom";
import CallBack from '../services/authService/CallBack';
// import SilentRenew from '../services/authService/SilentRenew';
import AuthProvider from '../services/authService/AuthContexts';

import FileWizard from '../components/upload_wizard/FileWizard';
import UsersWizzard from '../components/users/UsersWizzard';
import MainLayout from '../layouts/MainLayout';
const Router = () => {
    return (
    <Switch>
      <Route path="/" exact>
        <AuthProvider>
          <MainLayout>
            <FileWizard />
          </MainLayout>
        </AuthProvider>
      </Route>
      <Route path="/Users" exact>
        <AuthProvider>
          <MainLayout>
            <UsersWizzard />
          </MainLayout>
        </AuthProvider>
      </Route>
      <Route path="/loginCallback" render={(props) => <CallBack {...props}/>}></Route>
    </Switch>);
};
export default Router;

