import Container from 'react-bootstrap/Container';
import {Navbar, Nav} from 'react-bootstrap';


import { is_user_admin } from "../services/tools/SessionTools";


const Navigation = (props) => {

  let is_admin = is_user_admin();

  return (
    <Navbar className="ttd_nav" expand="lg">
      <Container>
        <Navbar.Brand href="/" variant="primary" >
          File Upload Tool
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">            
            {is_admin && <Nav.Link href="/Users">Users</Nav.Link>}
          </Nav>
          <Nav.Link>{props.User.email}&nbsp;&nbsp;</Nav.Link>
          {/* <Nav.Link href={SIGNOUT_URI}>
            <Button variant="outline-primary">Sign out</Button>
          </Nav.Link> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;