import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";

const App = () =>{
  return (
    <div>
      <BrowserRouter>    
        <Router>
        </Router>
      </BrowserRouter>
    </div>
  );
};

export default App;
