import React, { Component } from "react";
import { get_s3_signed_url, upload_file_to_aws } from "../../services/apiCallerService/ApiCaller";
import { Form, Button, ButtonGroup, Spinner } from 'react-bootstrap';

import { TOKEN } from "../../configs/oidc_constants";


class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      signed_url: "",
      upload_status: "no_upload"
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.reset = this.reset.bind(this);
    this.form = React.createRef();

    this.upload_file = this.upload_file.bind(this);
  }

  reset(){
    this.form.current.reset();
  }

  upload_file(event, path_setting) {
    let self = this;

    this.setState({upload_status: "uploading"}, ()=>{
    let token = JSON.parse(sessionStorage.getItem(TOKEN));
    path_setting["Filename"] = this.state.selectedFile.name;
    let username = token["profile"]["email"];;
    path_setting["User"] = username;

    get_s3_signed_url(token, path_setting)
      .then(signed_url_res=>{
        let signed_url = signed_url_res["URL"];
        let success = signed_url_res["Success"];
        if(success===false){
          self.props.show_error(signed_url_res["StatusCode"]);
        }

        if(signed_url!=="")
          upload_file_to_aws(signed_url, self.state.selectedFile, username)
          .then(res=>{
            if(res===200){
              if(success===true)
                self.setState({upload_status: "no_upload", selectedFile: null, signed_url: ""}, ()=>{
                  self.props.next_step();
                });
              else
                self.props.show_error(res);
            }
            else {
              self.props.show_error(res);
            }
          })
          .catch(function (error2) {
            console.log("Something went wrong with uploading file");
            console.log(error2);
          });

      })
      .catch(function (error) {
        console.log("Something went wrong with signed url");
        console.log(error);
      });
    });

  }

  onFileChange(event) {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount(){
    this.setState({selectedFile: null});
  }

  render() {
    let self = this;
    return (
      <div>
        <Form ref={this.form}>
          <Form.Group className={"wizrd_step wizard2_"+this.state.step}>
            <Form.Label>
              <strong>Upload an Excel file</strong>
            </Form.Label>
            <Form.Control
              type="file"
              onChange={self.onFileChange}
              disabled={self.state.step==="target"} />
          </Form.Group>

          <ButtonGroup className="btn-group-wizzard">
            <Button className="btn-space" variant="outline-secondary" onClick={this.props.reset}>Back</Button>
          {this.state.upload_status==="no_upload" ?
            <Button className="btn-space" disabled={this.state.selectedFile===null} variant={self.state.step==="target" ? "outline-secondary" : "outline-primary"} onClick={(event) => self.upload_file(event, self.props.selected_path)}>Upload</Button>:
            <div>
              <Spinner
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                  variant="secondary"/>
              <span>uploading...</span>
            </div>
          }

          </ButtonGroup>
        </Form>
      </div>
    );
  }
}

export default FileUploader;
