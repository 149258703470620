import React, { useEffect } from "react";
import UserManager from "./UserManager";

const CallBack = (props) => {
  const { history } = props;
  useEffect(() => {
    const signinRedirectCallback = () => {
      UserManager.Oidc.signinRedirectCallback()
        .then((user) => {
          try {
            const persistRedirectUrl = sessionStorage.getItem("redirectUrl");
            if (persistRedirectUrl != null && persistRedirectUrl) {
              history.push(persistRedirectUrl);
            } else {
              history.push("/");
            }
          } catch (error) {
            console.log("Error is here!");
            console.log(error);
          }
        })
        .catch(async (ex) => {
          const retrySignIn = parseInt(
            sessionStorage.getItem("retrySignIn") || "0",
            10
          );
          console.log("Err: 30", retrySignIn);
          if (retrySignIn > 0) {
            sessionStorage.setItem("retrySignIn", `${1}`);
            await UserManager.Oidc.signoutRedirect();
          } else {
            sessionStorage.setItem("retrySignIn", `${1}`);
            await UserManager.Oidc.signinRedirect();
          }
        });
    };
    signinRedirectCallback();
  }, [history]);

  return <></>;
};
export default CallBack;
