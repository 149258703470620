import React, { Component } from "react";
import {Form, Button, ButtonGroup, Alert} from 'react-bootstrap';
import { save_user } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";



class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_email: "",
      form_buckets: [],
      submitting: false,
      failed: false,
      validated: false,
      error_message: ""
    };
    this.submit_save_user = this.submit_save_user.bind(this);
    this.reset = this.reset.bind(this);
    this.check_is_user_unique = this.check_is_user_unique.bind(this);

    this.update_value = this.update_value.bind(this);
  
    this.set_path = this.set_path.bind(this);
    this.form = React.createRef();
  }

  set_path(path){
    let add = true;
    let form_buckets = this.state.form_buckets;

    if(add===true){
      form_buckets.push(path);
    }
    else{
      form_buckets.pop(path);
    }

    this.setState({"form_buckets": form_buckets});
  }


  check_is_user_unique(email){
    var result = this.props.user_paths.find(obj => {return obj["User"] === email });
    var is_user_unique = true;
    if(result!= null)
      is_user_unique = false;

    return is_user_unique;
  }

  submit_save_user(event) {
    let self = this;
    if(event) 
      event.preventDefault();

    const form1 = this.form.current;
    let form_is_valid = form1.checkValidity();
    let is_user_unique = this.check_is_user_unique(this.state.form_email);    

    if (form_is_valid === true && is_user_unique === true) {
       this.setState({"submitting": true}, ()=>{        
        let token = get_token();
          save_user(token, this.state.form_email, this.state.form_buckets, true).then((res)=>{
          if(res === true)
            self.props.switch_view("create_user");
          });
        });
      }
    else {
        let validation_message = "";
        if(!form_is_valid)
          validation_message+= "Failed form validation";
        if(!is_user_unique){
          if(validation_message !== "")
            validation_message += " and ";
          validation_message+= "Email is not unique";
        }

        this.setState({failed: true, error_message: validation_message});
    }
  }

  reset(){
    this.form.current.reset();
  }



  update_value(event){
    let input_value = event.target.value;
    let field_name = event.target.id;
    this.setState({[field_name]: input_value});
  }

  render() {
    let buckets = this.props.buckets;
    let self = this;

    return (
      <div>
        <Alert show={this.state.failed} variant="danger" dismissible onClose={() => this.setState({failed: false})} >
          <p>{this.state.error_message}</p>
        </Alert>
        
        <Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.submit_save_user}>
          <Form.Group className="mb-3" controlId="form_email">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={this.update_value} required />
          </Form.Group>
          <Form.Group>
            {buckets != null && buckets.length>0 &&buckets.map(function (bucket, index) {
              return (
                <div key={index}>
                  <Form.Check
                    name="group1"
                    type={"checkbox"}
                    label={bucket["DisplayName"] + " (" + bucket["Bucket"] + "/" + bucket["PathPrefix"] + ")"}
                    onChange={()=>self.set_path(bucket)}
                    checked={bucket["Checked"]} />
                </div>)
              })
            }
          </Form.Group>

          <ButtonGroup className="btn-group-wizzard">
            <Button className="btn-space" variant="outline-secondary" onClick={this.reset}>Back</Button>
            <Button className="btn-space" variant="outline-primary" disabled={this.state.selected_path===null} type="submit">Create User</Button>
          </ButtonGroup>
        </Form>
      </div>
    );
  }
}

export default AddUser;
