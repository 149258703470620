import React, { Component } from "react";
import {Table, Button} from 'react-bootstrap';

import DeleteUser from './DeleteUser';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      path_to_delete: null
    };
    this.delete_user = this.delete_user.bind(this);
    this.close_delete_and_reload_users = this.close_delete_and_reload_users.bind(this);
    this.hide_delete = this.hide_delete.bind(this);
    this.delete_component = React.createRef();
  }

  reset() {
    this.setState({selected_path: null}, ()=>{
      this.path_selector.current.reset();
      this.file_uploader.current.reset();
      this.wizzard.current.goToNamedStep("PathSelector");
    });
  }

  close_delete_and_reload_users(){
    this.setState({show_delete: false, path_to_delete: null},()=>{
      this.props.reload_users();
    });
  }
  
  hide_delete(){
    this.setState({show_delete: false, path_to_delete: null});
  }  

  delete_user(path){
    this.setState({show_delete: true, path_to_delete: path["User"]});
  }
  

  render() {
    let self = this;
    let users_list = this.props.users_list;
    return (
      <div>
        <DeleteUser 
          selected_path={this.state.path_to_delete} 
          show_delete={this.state.show_delete}
          close_delete_and_reload_users={this.close_delete_and_reload_users}
          hide_delete={this.hide_delete}
          reload_users={this.props.reload_users}
          ref={this.delete_component} />
          
        {users_list != null && users_list["user_paths"] != null &&
          <Table striped bordered>
            <thead>
              <tr>
                <th>User</th>
                <th>Permissions</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {users_list["user_paths"].map(function (path, index) {
                return (
                  <tr key={index}>
                    <td>{path["User"]}</td>
                    <td>
                      <Table>
                        <tbody>
                          {path["Permissions"].map(function (permission, index2) {
                            return (
                              <tr key={index2}>
                                <td>{permission["Bucket"]}/{permission["PathPrefix"]}</td>
                              </tr>)
                            })
                          }
                        </tbody>
                      </Table>  
                    </td>
                    <td>                      
                      <Button onClick={()=>self.props.edit_user(path)}>Edit</Button>
                    </td>
                    <td>                      
                      <Button onClick={()=>self.delete_user(path)}>Delete</Button>
                    </td>
                  </tr>)
                })
              }

            </tbody>
          </Table>
        }
      </div>
    );
  }
}

export default UsersList;
