import React, { Component } from "react";
import { ButtonGroup, Button, Alert } from 'react-bootstrap';

class UploadError extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
          <div>
            <Alert variant="danger">
              {this.props.error===401 && 
                <span>Please make sure you are on TTD VPN! If you are already, please contact administrator.</span>}

              {this.props.error===502 &&
                <span>Cannot overwrite existing file.</span>}                

              {(this.props.error !==502 && this.props.error !==401) &&
                <span>Something went wrong uploading the file! Please refresh the page to try again or contact administrator.</span>}                
            </Alert>
            <ButtonGroup className="btn-group-wizzard">
              <Button className="btn-space" variant="outline-secondary" onClick={this.props.reset}>Back</Button>
            </ButtonGroup>
          </div>
    );
  }
}

export default UploadError;
