import React, { Component } from "react";
import {Form, Button, ButtonGroup, Alert} from 'react-bootstrap';
import { save_user } from "../../services/apiCallerService/ApiCaller";
import { get_token } from "../../services/tools/SessionTools";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_email: "",
      form_buckets: [],
      submitting: false,
      failed: false,
      validated: false,
      error_message: "",

      user_to_edit: null,
      bucket_list: []
    };
    this.set_path = this.set_path.bind(this);
    this.setup_first_time = this.setup_first_time.bind(this);
    this.update_user = this.update_user.bind(this);
    this.form = React.createRef();
  }


  // if checkbox is changed
  set_path(path){


    let bucket_list = this.state.bucket_list;

    let number_of_bukcets = bucket_list.length;


    let i = 0;

    for(i=0;i<number_of_bukcets;i++){
      if(path["Bucket"] === bucket_list[i]["Bucket"]) {
          if(path["PathPrefix"] === bucket_list[i]["PathPrefix"]) {
            let flag = bucket_list[i]["checked"]!==null? Boolean(bucket_list[i]["checked"]) : true;
            bucket_list[i]["checked"]= !flag;
          }
        }
    }

    this.setState({"bucket_list": bucket_list});
  }

  setup_first_time(){
    let buckets = this.props.buckets;
    let user_to_edit = this.props.user_to_edit;

    let i =0;
    let j = 0;

    let number_of_bukcets = buckets.length;
    let number_of_user_permissions = user_to_edit["Permissions"].length;
    for(j=0;j<number_of_bukcets;j++) {
      let checked_flag = false;
      for(i=0;i<number_of_user_permissions;i++) {
        if(buckets[j]["Bucket"]===user_to_edit["Permissions"][i]["Bucket"] &
          buckets[j]["PathPrefix"]===user_to_edit["Permissions"][i]["PathPrefix"]) {
          checked_flag = true;
        }
      }
      buckets[j]["checked"] = checked_flag;
    }

    this.setState({bucket_list: buckets}, ()=>{
    });
  }


  update_user(event) {
    let self = this;
    if(event)
      event.preventDefault();

    const form1 = this.form.current;
    let form_is_valid = form1.checkValidity();

    let buckets = this.state.bucket_list;
    let selected_buckets = [];

    let number_of_buckets = buckets.length;
    for(let i=0;i<number_of_buckets;i++){
      if(buckets[i]["checked"])
        selected_buckets.push(buckets[i]);
    }

    if (form_is_valid === true) {
       this.setState({"submitting": true}, ()=>{


        let token = get_token();
          save_user(token, this.props.user_to_edit["User"], selected_buckets, false).then((res)=>{
          if(res === true){
            self.props.switch_view("create_user");
          }
          });
        });
      }
    else {
        let validation_message = "";
        if(!form_is_valid)
          validation_message+= "Failed form validation";

        this.setState({failed: true, error_message: validation_message});
    }
  }



  componentDidMount(){
    if(this.state.bucket_list.length===0)
      this.setup_first_time();
  }

  render() {
    let self = this;
    let buckets = this.state.bucket_list;

    return (
      <div>
        <Alert show={this.state.failed} variant="danger" dismissible onClose={() => this.setState({failed: false})} >
          <p>{this.state.error_message}</p>
        </Alert>

        <Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.update_user}>
          <Form.Group className="mb-3" controlId="form_email">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" readOnly={true} value={this.props.user_to_edit["User"]} />
          </Form.Group>
          <Form.Group>
            {buckets != null && buckets.length>0 &&buckets.map(function (bucket, index) {
              return (
                <div key={index}>
                  <Form.Check
                    name="group1"
                    type={"checkbox"}
                    label={bucket["DisplayName"] + " (" + bucket["Bucket"] + "/" + bucket["PathPrefix"] + ")"}
                    onChange={()=>self.set_path(bucket)}
                    checked={bucket["checked"]} />
                </div>)
              })
            }
          </Form.Group>

          <ButtonGroup className="btn-group-wizzard">
            <Button className="btn-space" variant="outline-secondary" onClick={this.reset}>Back</Button>
            <Button className="btn-space" variant="outline-primary" disabled={this.state.selected_path===null} type="submit">Update User</Button>
          </ButtonGroup>
        </Form>
      </div>
    );
  }
}

export default EditUser;
